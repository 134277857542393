import React from 'react'
import ContactInfo from '../components/ContactInfo'


function Contact() {
    return (
        <>
            <ContactInfo />
        </>
    )
}

export default Contact