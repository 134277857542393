import React from 'react'
import GallerySellerie from '../components/gallery/GallerySellerie';


function Sellerie() {
    return (
        <GallerySellerie />
    )
}

export default Sellerie