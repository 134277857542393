import React from 'react'
import GalleryBoutique from '../components/gallery/GalleryBoutique'

function Boutique() {
    return (
        <>
            <GalleryBoutique />
        </>
    )
}

export default Boutique