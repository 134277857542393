import React from 'react'
import Presentation from '../components/Presentation'
import ContactInfo from '../components/ContactInfo'
import Banner from '../components/Banner'



function Home() {
    return (
        <>
            <Presentation />
        </>
    )
}

export default Home