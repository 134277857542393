import React from 'react'
import Legalite from '../components/Legalite'

function MentionsLegales() {
  return (
    <>
      <Legalite />
    </>
  )
}

export default MentionsLegales