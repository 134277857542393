import sell1 from "../.././assets/img/gallery/sellerie/sell1.webp"
import sell2 from "../.././assets/img/gallery/sellerie/sell2.webp"
import sell3 from "../.././assets/img/gallery/sellerie/sell3.webp"
import sell4 from "../.././assets/img/gallery/sellerie/sell4.webp"
import sell5 from "../.././assets/img/gallery/sellerie/sell5.webp"
import sell6 from "../.././assets/img/gallery/sellerie/sell6.webp"

export const photos = [
  {
    src: sell1,
    width: 1,
    height: 1
  },
  {
    src: sell2,
    width: 3,
    height: 2
  },
  {
    src: sell3,
    width: 1,
    height: 1
  },
  {
    src: sell4,
    width: 1,
    height: 1
  },
  {
    src: sell5,
    width: 1,
    height: 1
  },
  {
    src: sell6,
    width: 16,
    height: 9
  },
];